import React, { useState } from 'react'
import { validateEmail, sanityImageUrl } from '../../../utils/format'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ expanding }) => {
  if (!expanding || expanding.isHide) return null

  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const formHandler = (evt) => {
    evt.preventDefault()
    const { email } = evt.target

    if (!email.value || loading || !validateEmail(email.value)) {
      return
    }

    setLoading(true)

    fetch('https://app.maximustribe.com/community/access', {
      method: 'POST',
      body: JSON.stringify({ email: email.value, href: window.location.href }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        if (response.ok) {
          return response.json()
        }
        return Promise.reject(response)
      })
      .then(data => {
        setTimeout(() => {
          setSubmitted(true)
          setLoading(false)
        }, 600)
      })
      .catch(error => console.warn(error))
  }

  return (
    <div className={styles.expanding}>
      <div className={styles.wrap}>
        <div className={styles.map}>
          <img src={sanityImageUrl(expanding.image)} className={styles.image} alt={expanding.image?.caption} />
          <p className={styles.available}>{expanding.availableText}</p>
        </div>
        <div className={styles.info}>
          <h2 className={styles.title}>{expanding.title}</h2>
          <p className={styles.description}>{expanding.description}</p>
          {submitted ? (
            <p className={styles.submitted}>Thanks! We'll be in touch</p>
          ) : (
            <form 
              className={cx(styles.form, {
                [styles.formLoading]: loading,
              })} 
              onSubmit={formHandler}
            >
              <input type="emails" name="email" className={styles.formEmail} placeholder="Enter your email"/>
              <input type="submit" value="Submit" className={styles.formSubmit}/>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
